.topHeader{
    padding: 3px 0;
    border-bottom: 1px solid #ddd;
    .btn{
        padding: 1px 8px;
        font-size: 14px;
        @media screen and (max-width: 767px) {
            font-size: 12px;
            padding: 1px 4px;
        }
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        li{
            a{
                color: #333;
                text-decoration: none;
                padding: 1px 8px;
                img{
                    width: 20px;
                }
            }
        }
    }
    .topHeaderright{
        ul+ul{
            margin-left: 20px;
        }
    }
}
.middleHeader{
    padding: 20px 0;
    .logo_Wrap{
        img{
            width: 120px;
        }
    }
    @media screen and (max-width: 991px) {
        border-bottom: 1px solid #d1c9c9;
        padding: 10px 0;
    }    
    .searchWrapper{
        display: none;
        align-items: center;
        border: 1px solid #d1c9c9;
        @media screen and (min-width: 992px) {
            display: flex;
        }
    }
    .searchSelect {
        padding: 0 30px 0 20px;
        height: 38px;
        line-height: 38px;
        border: none;
        -webkit-appearance: none;
        appearance: none;
        color: #000;
        background: #fff url(../../assets/images/icon/angle-dwon.png) right 10px center no-repeat;
        &:focus{
            outline: none;
        }
    }
    .searchGroup{
        -ms-flex: 1 0 0;
        flex: 1 0 0;
        display: flex;
        border-left: 1px solid #d1c9c9;
        input{
            -ms-flex: 1 0 0;
            flex: 1 0 0;
            height: 38px;
            border: none;
            font-size: 16px;
            padding-left: 16px;
            &:focus{
                outline: none;
            }
        }
    }
    .headerSearchBtn{
        width: 56px;
        border-radius: 0;
    }
  
}
.bottomHeader{
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    @media screen and (min-width: 992px) {
        display: block !important;
    }
    @media screen and (max-width: 991px) {
        border-bottom: none;
    }
    
    ul{
        margin: 0;
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
        >li{
            @media screen and (max-width: 991px) {
                width: 100%;
                border-bottom: 1px solid #f3f3f3;
            }
            >a{
                padding: 7px 0;
                display: block; 
                text-decoration: none;
                color: #000000;
                font-size: 14px;
                @media screen and (max-width: 991px) {
                    font-size: 16px;
                }
                &:hover{
                    color: var(--primary-color);
                }
            }
        }
    }
}

.mobile-menu-btn {
    padding: 0px;
    position: relative;
    z-index: 99999;
    @media screen and (min-width: 992px) {
        display: none;
    }
  }
  
  .mobile-menu-btn:focus {
    text-decoration: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .mobile-menu-btn .toggler-icon {
    width: 25px;
    height: 3px;
    background-color: #333; 
    display: block;
    margin: 5px 0;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  
  .mobile-menu-btn.active .toggler-icon:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 7px;
  }
  
  .mobile-menu-btn.active .toggler-icon:nth-of-type(2) {
    opacity: 0;
  }
  
  .mobile-menu-btn.active .toggler-icon:nth-of-type(3) {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    top: -9px;
  }

  .searchMobileBtn{
    font-size: 24px;
    margin-left: auto;
    text-align: right;
    @media screen and (min-width: 992px){
        display: none;
    }
  }