.eachProductImage{
    position: relative;
    >img{
        width: 100%;
        display: block;
        
        height: 294px;
        object-fit: contain;
    }
    .pBadge{
        position: absolute;
        top: 15px;
        left: 15px;       
        font-size: 14px;
        color: #fff;
        padding: 7px 8px;
        border-radius: 4px;
        line-height: 1;
        &.discount{
            background: #ff0000;
        }
        &.new{
            background: #0078ff;
        }
    }
}
.eachProductText{
    position: relative;
    padding-top: 20px;
    .favouriteStatus{
        font-size: 22px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    h6{
        font-size: 14px;
        color: #666666;
        font-weight: 400;
    }
    h2{
        font-size: 20px;
        color: #000000;
        font-weight: 400;
        margin-bottom: 15px;
    }
    h3{
        font-size: 20px;
        color: #000000;
    }
    .moreFetureImgThumb{
        >ul{
            display: flex;
            list-style: none;
            padding-left: 0;
            >li{
                -ms-flex: 0 0 40px;
                flex: 0 0 40px;
                cursor: pointer;
                &+li{
                    margin-left: 18px;
                }
                &.active{
                    >img{
                        border: 1px solid #000000;
                       
                    }
                }
                >img{
                    width: 100%;
                    display: block;
                    height: 40px;
                    object-fit: contain;
                    background: #f6f6f6;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }
        }
    }

    

}


.ratingOrderWrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rating{
        font-size: 16px;   
        line-height: 1;     
        >ul{
            list-style: none;
            padding-left: 0;
            display: flex;
            color: #ff9000;
            margin: 0;
            >li + li{
                margin-left: 1px;
            }
        }
    }
    .orderCount{
        color: #666666;
        font-size: 14px;
    }

}
