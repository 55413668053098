.eachRecentlyViewImg{
    position: relative;
    >img{
        width: 100%;
        display: block;
    }
   

    .favouriteStatus{
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        text-align: center;
        background: #fff;
        line-height: 40px;
        border-radius: 50%;
        font-size: 22px;
    }
}
.eachRecentlyViewText{
    .suitColorCombination{
        ul{
            display: flex;
            list-style: none;
            padding-left: 0;
            margin-left: 0;
            margin-top: 16px;
            margin-bottom: 8px;
            li{
                &+li{
                    margin-left: 6px;
                }
                &.active{                    
                    span{
                        border: 1px solid #000;
                    }
                }
                span{
                    display: block;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }
        }
    }
    h2{
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 10px;
    }
    h3{
        font-weight: 700;
        font-size: 20px;
    }
}
