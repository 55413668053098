.siteFooter{
    background: #073761;
    padding: 120px 0 0;
    position: relative;
    margin-top: 60px;
    .topFooter{
        padding: 50px 0;
        @media screen and (max-width: 991px) {
            padding: 0px 0 20px;
        }
    }
    .bottomFooter{
        padding: 20px 0;
        p{
            color: #fff;
            margin-bottom: 0;
            @media screen and (max-width: 991px) {
                text-align: center;
            }
        }
        .weAcceptCard{
            display: flex;
            align-items: center;
            justify-content: center;           
            @media screen and (max-width: 991px) {
                margin-top: 16px;
            }
            @media screen and (min-width: 992px) {
                justify-content: flex-end;
            }
            
            li{
                color: #fff;
                &+li{
                    margin-left: 10px;
                }
            }
        }
    }
    .h4{
        color: #e8e8e8;
        font-size: 20px;
        margin-bottom: 20px;
        @media screen and (max-width: 991px) {
            margin-bottom: 10px;
        }
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{ 
            color: #e8e8e8;
            a{
                color: #e8e8e8;
                display: block;
                text-decoration: none;
                line-height: 30px;
            }
        }
    }
}


.newsLetter{
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    .newletterWrap{
        display: flex;
        border-radius: 30px;
        overflow: hidden;
        input{
            flex: 1 0 0;
            border: none;
            padding: 0 20px;
            ::placeholder{
                font-weight: 300;
                color: #919191;
                opacity: 1;
            }
            :-moz-placeholder{
                font-weight: 300;
                color: #919191;
                opacity: 1;
            }
            ::-moz-placeholder{
                color: #919191;
                opacity: 1;
            }
        }
        button{
            border-radius: 0;
            background: #073761;
            padding: 16px 20px;
            @media screen and (max-width: 991px) {
                padding: 10px 20px;
                >span{
                    display: none;
                }
            }
        }
    }
    .newsLetterInnr{
        background: #144795;
        padding: 44px 0;
        @media screen and (max-width: 991px) {
            padding: 20px 30px;
        }
        ul{
            display: flex;
            justify-content: flex-end;
            margin: 0;
            padding: 0;
            @media screen and (max-width: 991px) {
                margin: 20px 0 0;
            }
            li+li{
                margin-left: 20px;
            }
            a{
                font-size: 20px;
            }
        }
    }
}